<template>
    <div class="modal secondary" v-if="modelValue" :class="modelValue ? `active ${classlist}` : `${classlist}` ">
        <div class="modal_container">
            <h2>Insert Link</h2>
            <form class="setting_wpr w-100">
                <div class="group_item">
                    <label class="input_label">Select link</label>
                    <div class="field_wpr">
                        <multiselect
                            v-model="form.link"
                            :groups="true"
                            :searchable="true"
                            :options="links"
                            valueProp="id"
                            label="title"
                            placeholder="Select link"
                            :groupSelect="false"
                            @select="handleLinkSelect"
                            :groupHideEmpty="true"
                        >
                            <template v-slot:option="{ option }">
                                 <span v-if="option.$isLabel">
                                     {{ option.$groupLabel }}
                                 </span>
                                 <span v-else>{{ option.title }}</span>
                           </template>
                        </multiselect>
                    </div>
                </div>
                <div class="group_item mt-3">
                    <label class="input_label">URL</label>
                    <div class="field_wpr">
                        <input name="url" v-model="form.url" placeholder="ex. https://superfitcoaching.com" />
                    </div>
                    <label class="text-danger" v-show="urlError">{{ urlErrorText }}</label>
                </div>
                <div class="group_item mt-3">
                    <label class="input_label">Text</label>
                    <div class="field_wpr">
                        <input name="text" v-model="form.text" placeholder="ex. Superfit Coaching, LLC" />
                    </div>
                    <label class="text-danger" v-show="textError">The text field is required</label>
                    <!-- <span class="font-12 text-muted">ex. OnboardMe</span> -->
                </div>
                <div class="group_item">
                    <label for="target" class="checkbox justify-content-start">
                        <input name="target" v-model="form.target" :true-value="1" :false-value="0" type="checkbox" id="target" hidden />
                        <span><i class="fas fa-check"></i></span>&nbsp; Open in new tab
                    </label>
                </div>
                <div class="action_wpr m-0 p-0">
                    <button type="button" class="btn cancel_btn mr-2" @click="closeModal()">Cancel</button>
                    <button type="button" class="btn save_btn" @click="handleInsertLink()">Insert</button>
                </div>
            </form>
        </div>
    </div>

</template>

<script>
    import Multiselect from '@vueform/multiselect'
    import { mapState, mapActions } from 'vuex'

    export default {
        name: 'Define Link',

        props: {
            modelValue: Boolean,
            insertLink: Function,
            classlist: String,
            selectedText: {
                type: String,
                default: '',
            },
            setEditorFocus: {
                type: Function,
                default: () => {},
            }
        },

        data () {
            return {
                form: {
                    link: '',
                    url: '',
                    text: '',
                    target: '',
                },
                urlError: false,
                textError: false,
                urlErrorText: '',
            };
        },

        components: {
            Multiselect
        },

        watch: {
            modelValue (val) {
                if (val) {
                    const vm = this;
                    vm.form      = { link: '', url: '', text: vm.selectedText, target: '' };
                    vm.urlError  = false;
                    vm.textError = false;

                    if (!Object.keys(vm.links).length) {
                        vm.getDefinedLinks();
                    }
                }
            },
        },

        computed: mapState({
            links: state => state.commonModule.definedLinks,
        }),

        methods: {
            ...mapActions ({
                getDefinedLinks: 'commonModule/getDefinedLinks',
            }),

            closeModal () {
                const vm = this;

                vm.setEditorFocus();
                vm.$emit('update:modelValue', false);
            },

            handleLinkSelect (id, link) {
                const vm = this;

                vm.form.link  = link.id;
                vm.form.url   = link.public_url;
                vm.form.text  = link.title;
            },

            handleInsertLink () {
                const vm = this;

                if (vm.validateLinkForm()) {
                    vm.insertLink(vm.form);
                } else {
                    return;
                }
            },

            validateLinkForm () {
                const vm = this;

                vm.textError = vm.form.text ? false : true;

                if (vm.form.url) {
                    if (!vm.isValidUrl(vm.form.url)) {
                        vm.urlErrorText = 'The url is not valid';
                        vm.urlError = true;
                    } else {
                        vm.urlError = false;
                    }
                } else {
                    vm.urlErrorText = 'The url field is required';
                    vm.urlError = true;
                }

                if (vm.textError || vm.urlError) {
                    return false;
                } else {
                    return true;
                }
            },

            isValidUrl(string) {
                try {
                    new URL(string);
                    return true;
                } catch (err) {
                    return false;
                }
            }
        }
    }
</script>
<style scoped>
    .checkbox {
        align-items: center;
        justify-content: flex-start;
        font-size: 14px;
        line-height: 18px;
        margin: 25px 0;
    }
</style>
