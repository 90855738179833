<template>
    <div class="preview_area show_sidebar" v-if="modelValue" :class="modelValue ? `active ${classlist}`:`${classlist}`" @click.self="closeModal()">
        <div class="content_area library">
            <button class="close-btn" @click="closeModal()"><i class="fas fa-times"></i></button>
            <image-upload v-model="image" :crop="true" image-type="all" :handle-image="handleInsertImage" :ref="uniqueId" :show-library="true" :classlist="classlist"/>
        </div>
        <div class="action_wpr">
            <button type="button" class="btn cancel_btn" @click="closeModal()">Cancel</button>
            <button  v-if="showAction" type="button" class="btn save_btn ml-2" @click="handleInsertImage()">Insert</button>
        </div>
    </div>
</template>

<script>
    import ImageUpload from '@/components/image-library/ImageUpload'
    import { uuid } from 'vue-uuid'

    export default {
        name: 'Image Library',

        data () {
            return {
                image: '',
                uniqueId: 'library-'+uuid.v4(),
                showAction: true,
            };
        },

        props: {
            modelValue: Boolean,
            insertImage: Function,
            classlist: String,
        },

        emits: ['update:modelValue'],

        components: {
            ImageUpload,
        },

        watch: {
            modelValue () {
                const vm = this;
                vm.image = '';
            },
        },

        methods: {
            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            handleInsertImage () {
                const vm = this;

                if (vm.insertImage) {
                    vm.insertImage(vm.image);
                }
            },
        }
    }
</script>

<style scoped>
    /* .content_area{
        padding: 40px 50px 20px 50px;
    } */
    .global_setting .content_area, .content_area {
        padding: 40px 40px 20px 40px;
        flex-grow: 1;
    }   
    .action_wpr{
        padding: 20px 40px;
        border-top: 1px solid #e9e9e9;
        margin: auto 0 0 0;
        background: #fff;
    }    
    .close-btn{
        right: 15px;
        left: auto;
        background: rgba(50,55,59,.6);
        color: #fff;
        height: 20px;
        width: 20px;
        font-size: 12px;
        position: absolute;
        right: 15px;
        top: 15px;
        border-radius: 50%;
    }
    .preview_area.show_sidebar{
        width: 100%;
        left: 0;
        display: flex;
        flex-direction: column;
    }
    /* .image_lib .modal_container {
        width: 1140px;
        height: 644px;
    }

    .setting_wpr {
        width: 100%;
    }
    .close_btn{
        position: absolute;
        right: 15px;
        top: 15px;
        border: 0;
        background: rgba(50,55,59,.6);
        font-size: 12px;
        color: #fff;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        min-width: 20px;
    } */

    :deep(.upload_area) {
        height: 400px;
    }
</style>
