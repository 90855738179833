<template>
    <div class="redactor-container" :id="`${editorUuid}-container`" v-click-outside="closeEmojiModal">
        <textarea :id="editorUuid" class="redactor-editor" :name="name" rows="8" />
        <!-- <div :id="`${editorUuid}-toolbar`" class="redactor-toolbar-external"></div> -->
        <define-links v-model="defineLinkModal" :placeholder="placeholder" :insert-link="insertLink" :selected-text="selectedText" :set-editor-focus="setEditorFocus" :classlist="classlist" />
        <redactor-filemanager v-model="fileManagerModal" :placeholder="placeholder" :insert-file="insertFile" :selected-text="selectedText" :set-editor-focus="setEditorFocus" :classlist="classlist" />
        <emoji-picker :on-select-emoji="setEmoji" class="custom-emoji-picker redactor-emoji-picker" :toggle-watcher="emojiPicker" :ref="`${editorUuid}-emoji-picker`" v-click-outside="closeEmojiModal" :is-redactor="true" />
        <image-library v-model="imageLibraryModal" :insert-image="insertImage" :classlist="classlist" />
    </div>
</template>

<script>
    import '@/assets/plugins/redactor/redactor'
    import '@/assets/plugins/redactor/_plugins/alignment/alignment'
    import '@/assets/plugins/redactor/_plugins/fontcolor/fontcolor'
    import '@/assets/plugins/redactor/_plugins/definedlinks/definedlinks'
    import '@/assets/plugins/redactor/_plugins/fontsize/fontsize'
    import '@/assets/plugins/redactor/_plugins/widget/widget'
    import '@/assets/plugins/redactor/_plugins/filemanager/filemanager'
    import '@/assets/plugins/redactor/_plugins/counter/counter'
    import '@/assets/plugins/redactor/_plugins/video/video'
    import '@/assets/plugins/redactor/redactor.css'

    import DefineLinks from '@/components/DefineLinks'
    import RedactorFilemanager from '@/components/RedactorFilemanager'
    import constants from '@/utils/Constant'
    import ImageLibrary from '@/components/image-library/Library'
    import EmojiPicker from '@/components/EmojiPicker'

    import Pickr from '@simonwep/pickr'
    import '@simonwep/pickr/dist/themes/monolith.min.css'

    import { uuid } from 'vue-uuid'
    import { mapState, mapActions } from 'vuex'

    export default {
        name: 'Redactor Editor',

        props: {
            name: String,
            modelValue: String,
            emailEditor: {
                type: Boolean,
                default: true
            },
            showFontHighlight: {
                type: Boolean,
                default: true
            },
            placeholder: {
                type: String,
                default: null,
            },
            personalize: {
                type: [Object, Array],
                default: () => [],
            },
            plugins: {
                type: [Object, Array],
                default: () => [],
            },
            hideToolbars: {
                type: [Object, Array],
                default: () => [],
            },
            defaultValue: {
                type: String,
                default: '',
            },
            minHeight: {
                type: String,
                default: '200px',
            },
            resetWatcher: Number,
            classlist: String,
            defaultValueWatcher: Number,
            isSubTextPlugin: {
                type: Boolean,
                default: false,
            },
            hasImportTemplate: {
                type: Boolean,
                default: false,
            },
            importTemplateHandler: Function,
            maxHeight: {
                type: String,
                default: '600px',
            },
            trackFocusout: Function,
            resetToDefautWatcher: Number,
        },

        emits: ['update:modelValue'],

        data () {
            return {
                editorUuid: 'redactor-'+uuid.v4(),
                content: '',
                defaultPlugins: ['alignment', 'filemanager-custom', 'iconic', 'fontsize', 'fontfamily', 'emoji', 'definedlinks-custom', 'email-buttons', 'personalize', 'counter', 'image-library', 'fontcolor-custom'],
                defineLinkModal: false,
                fileManagerModal: false,
                redactorEditor: false,
                showEmoji: false,
                defaultPersonalize: constants.personalize,
                imageLibraryModal: false,
                emojiPicker: false,
                redactorEditorRange: null,
                emojiButton: null,
                selectedText: '',
                fontColorPicker: false,
                colorPicker: null,
                isCustomColorPickerInit: false,
                colorPickersource: 'other',
                colorPickerArgs: {},
                colorPickerObj: {}
            };
        },

        components: {
            DefineLinks,
            EmojiPicker,
            ImageLibrary,
            RedactorFilemanager,
        },

        watch: {
            defaultValue (content) {
                const vm = this;

                if (content && content.length) {
                    vm.redactorEditor.source.setCode(content);
                }
            },

            user (user) {
                const vm = this;

                vm.initRedactor();
            },

            resetWatcher () {
                const vm = this;

                if (vm.defaultValue && vm.defaultValue.length) {
                    vm.redactorEditor.source.setCode(vm.defaultValue);
                } else {
                    vm.redactorEditor.source.setCode('<p></p>');
                }
            },

            defaultValueWatcher () {
                const vm = this;

                if (vm.defaultValue && vm.defaultValue.length) {
                    vm.redactorEditor.source.setCode(vm.defaultValue);
                } else {
                    vm.redactorEditor.source.setCode('<p></p>');
                }
            },

            resetToDefautWatcher () {
                const vm = this;

                if (vm.modelValue && vm.modelValue.length) {
                    vm.redactorEditor.source.setCode(vm.modelValue);
                } else {
                    vm.redactorEditor.source.setCode('<p></p>');
                }
            },

            emojiPicker (val) {
                if (!val) {
                    const vm = this;
                    const element = document.querySelector('.custom-emoji-picker');

                    vm.emojiButton.setInactive();

                    setTimeout(function () {
                        element.style.position  = 'relative';
                    }, 100);
                }
            },
        },

        computed: mapState({
            user: state => state.authModule.user,
            buttons: state => state.commonModule.emailButtons,
            redactorContentLoaded: state => state.commonModule.redactorContentLoaded,
            favoriteColors: state => state.commonModule.favoriteColorList,
            favoritc: state => state.commonModule.favoriteColors,
        }),

        beforeUnmount () {
            const vm = this;

            vm.destroy()
        },

        mounted () {
            const vm = this;

            vm.initRedactor();

            const groupButtons = document.querySelectorAll('.v3-groups button');
            const emojisButtons = document.querySelectorAll('.v3-group button');

            groupButtons.forEach((button) => {
                button.setAttribute('type', 'button');
            });

            emojisButtons.forEach((button) => {
                button.setAttribute('type', 'button');
            });

            const imagePlugin = document.getElementById(`editor-image-${vm.editorUuid}`);
            const linkPlugin  = document.getElementById(`editor-link-${vm.editorUuid}`);
            const emojiPlugin = document.getElementById(`editor-emoji-${vm.editorUuid}`);

            if (imagePlugin) {
                imagePlugin.addEventListener('click', () => {
                    vm.checkEditorFocus();
                });
            }

            if (linkPlugin) {
                linkPlugin.addEventListener('click', () => {
                    vm.checkEditorFocus();
                });
            }

            if (emojiPlugin) {
                emojiPlugin.addEventListener('click', () => {
                    vm.checkEditorFocus();
                });
            }

            if (vm.favoriteColors.length == 0) {
                vm.getFavoriteColors();
            }
        },

        methods: {
            ...mapActions({
                getEmailButtons: 'commonModule/getEmailButtons',
                getFavoriteColors: 'commonModule/getFavoriteColors',
            }),

            closeEmojiModal (e) {
                const vm = this;

                if (!e || (e.target.classList && e.target.classList.value != 'fa fa-smile')) {
                    vm.emojiPicker = false;
                }
            },

            insertLink (link) {
                const vm      = this;
                const url     = link.url ? ((link.url.indexOf('://') === -1) ? 'http://' + link.url : link.url) : '';
                const target  = (link.target == 1) ? '_blank' : '';

                if (vm.redactorEditorRange) {
                    const node = document.createElement('a');
                          node.setAttribute('href', url);
                          node.setAttribute('target', target);
                          node.appendChild(document.createTextNode(link.text));

                    vm.redactorEditor.selection.restore();
                    vm.redactorEditor.insertion.insertRaw('');
                    vm.redactorEditorRange.collapse(true);
                    vm.redactorEditorRange.insertNode(node);
                    vm.redactorEditorRange.setEndBefore(node);
                    vm.redactorEditor.selection.setRange(vm.redactorEditorRange);
                } else {
                    vm.redactorEditor.selection.restore();
                    vm.redactorEditor.insertion.insertRaw(`<a href="${url}" target="${target}">${link.text}</a>`);
                }

                // vm.redactorEditor.selection.restore();
                // vm.redactorEditor.selection.save();

                // if ( vm.selectedText == link.text ) {
                //     vm.redactorEditor.insertion.insertRaw(`<a href="${url}" target="${target}">${link.text}</a>`);
                // } else {
                //     vm.redactorEditor.insertion.insertRaw(`<a href="${url}" target="${target}">${vm.selectedText ? vm.selectedText : link.text}</a>`);
                // }

                vm.redactorEditor.selection.restore();
                vm.defineLinkModal = false;
            },

            insertFile (link) {
                const vm      = this;
                const url     = link.url ? ((link.url.indexOf('://') === -1) ? 'http://' + link.url : link.url) : '';
                const target  = (link.target == 1) ? '_blank' : '';

                if (vm.redactorEditorRange) {
                    const node = document.createElement('a');
                          node.setAttribute('href', url);
                          node.setAttribute('target', target);
                          node.appendChild(document.createTextNode(link.name ? link.name : (link.original_name ? link.original_name : 'test')));

                    vm.redactorEditor.selection.restore();
                    vm.redactorEditor.insertion.insertRaw('');
                    vm.redactorEditorRange.collapse(true);
                    vm.redactorEditorRange.insertNode(node);
                    vm.redactorEditorRange.setEndBefore(node);
                    vm.redactorEditor.selection.setRange(vm.redactorEditorRange);
                } else {
                    vm.redactorEditor.selection.restore();
                    vm.redactorEditor.insertion.insertRaw(`<a href="${url}" target="${target}">${link.name ? link.name : 'test'}</a>`);
                }

                vm.redactorEditor.selection.restore();
                vm.fileManagerModal = false;
            },

            insertImage (image) {
                const vm      = this;

                if (vm.redactorEditorRange) {

                    // For remove selected image
                    const selectedImage = vm.redactorEditor.selection.getElement();

                    if (selectedImage) {
                        selectedImage.remove();
                    }

                    const div = document.createDocumentFragment();

                    const node = document.createElement('figure');
                          node.setAttribute('class', 'redactor-component');
                          node.setAttribute('data-redactor-type', 'image');
                          node.setAttribute('contenteditable', false);
                          node.setAttribute('tabindex', -1);

                    const imageNode = document.createElement('img');
                          imageNode.setAttribute('src', image);

                    const lineBreakNode = document.createElement('p');

                    node.appendChild(imageNode);

                    div.appendChild(node);
                    div.appendChild(lineBreakNode);

                    vm.redactorEditorRange.collapse(true);
                    vm.redactorEditorRange.insertNode(div);
                    vm.redactorEditorRange.setEndBefore(imageNode);
                    vm.redactorEditor.selection.setRange(vm.redactorEditorRange);
                } else {
                    vm.redactorEditor.selection.restore();
                    vm.redactorEditor.insertion.insertRaw(`<figure class="redactor-component" data-redactor-type="image" contenteditable="false" tabindex="-1"><img src="${image}" /></figure><p></p>`);
                }

                vm.imageLibraryModal = false;
            },

            setEmoji (emoji) {
                const vm = this;

                vm.redactorEditor.selection.restore();
                vm.redactorEditor.insertion.insertText(emoji);
                vm.closeEmojiModal();
            },

            destroy () {
                const vm = this

                Redactor(`#${vm.editorUuid}`, 'destroy');
                vm.redactorEditor = null;
            },

            handleInput (content) {
                const vm = this;

                vm.$emit('update:modelValue', content);
                vm.parseScriptFromContent(content);

                if (vm.trackFocusout) {
                    vm.trackFocusout()
                }
            },

            initRedactor () {
                /*global Redactor*/
                /*eslint no-undef: "error"*/
                const vm = this;

                const buttonsHide = ['link'];
                const plugins = vm.plugins.length ? JSON.parse(JSON.stringify(vm.plugins)) : vm.defaultPlugins;

                if (!vm.emailEditor) {
                    if (!plugins.includes('widget')) {
                        plugins.push('widget');
                    }

                    if (!plugins.includes('video')) {
                        plugins.push('video');
                    }
                }

                if (vm.hideToolbars.length) {
                    vm.hideToolbars.forEach((tool) => {
                        buttonsHide.push(tool);
                    });
                }

                const callbacks = {
                    changed: function (html) {
                        vm.handleInput(html);
                        return html
                    }
                };

                if (vm.isSubTextPlugin) {
                    vm.initSubTextPlugin();

                    if (!plugins.includes('sub-text')) {
                        plugins.push('sub-text');
                    }
                }

                vm.updateRedactorPluginCloserFunc();
                vm.initFontFamilyPlugin();
                vm.initDefineLinksCustomPlugin();
                vm.initEmojiPlugin();
                vm.initPersonalizePlugin();
                vm.initImageLibraryPlugin();
                vm.initFontColorPlugin();
                vm.initEmailButtonsPlugin();
                vm.initFileManagerCustomPlugin();

                vm.redactorEditor = Redactor(`#${vm.editorUuid}`, {
                                        plugins,
                                        buttonsHide,
                                        buttonsAddBefore: { after: 'fontsize', buttons: ['fontcolor-custom'] },
                                        callbacks,
                                        focusEnd: false,
                                        styles: true,
                                        minHeight: vm.minHeight,
                                        maxHeight: vm.maxHeight,
                                        imagePosition: true,
                                        imageResizable: true,
                                        autoparseVideo: (vm.emailEditor == true) ? false : true,
                                        buttonsAddAfter: {
                                            after: 'deleted',
                                            buttons: ['underline']
                                        },
                                        // fileUpload: `${process.env.VUE_APP_API_URL}/api/v3/redactor/${vm.user.id}/file-upload`,
                                        // toolbarExternal: `#${vm.editorUuid}-toolbar`,
                                        linkNewTab: true,
                                    });

                if (vm.hasImportTemplate) {
                    vm.redactorEditor.statusbar.add('import-template', `<a id="${vm.editorUuid}-import-template" class="import-template"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.43432 0.234312C7.74672 -0.078104 8.25328 -0.078104 8.56568 0.234312L11.7657 3.43431C12.0781 3.74674 12.0781 4.25326 11.7657 4.56569C11.4533 4.8781 10.9467 4.8781 10.6343 4.56569L8.8 2.73137V10.4C8.8 10.8418 8.44184 11.2 8 11.2C7.55816 11.2 7.2 10.8418 7.2 10.4V2.73137L5.36569 4.56569C5.05326 4.8781 4.54674 4.8781 4.23431 4.56569C3.9219 4.25326 3.9219 3.74674 4.23431 3.43431L7.43432 0.234312Z" fill="#999999"/>
<path d="M5.6 8.42745C5.6 8.02889 5.6 7.82969 5.5306 7.67409C5.45022 7.49393 5.3061 7.34977 5.12593 7.26945C4.97035 7.20001 4.7711 7.20001 4.37258 7.20001C3.83677 7.20001 3.39445 7.20001 3.03237 7.22473C2.65694 7.25033 2.30966 7.30513 1.97542 7.44361C1.19133 7.76841 0.568368 8.39137 0.243584 9.17545C0.105136 9.50969 0.0503042 9.85697 0.0246962 10.2324C-7.75158e-06 10.5945 -7.71963e-06 11.0367 2.80366e-07 11.5726V11.6275C-7.71963e-06 12.1632 -7.75158e-06 12.6055 0.0246962 12.9676C0.0503042 13.3431 0.105136 13.6903 0.243584 14.0246C0.568368 14.8087 1.19133 15.4316 1.97542 15.7564C2.30966 15.8949 2.65694 15.9497 3.03237 15.9753C3.39445 16 3.83674 16 4.37255 16H11.6274C12.1633 16 12.6055 16 12.9676 15.9753C13.343 15.9497 13.6903 15.8949 14.0246 15.7564C14.8086 15.4316 15.4316 14.8087 15.7564 14.0246C15.8949 13.6903 15.9497 13.3431 15.9753 12.9676C16 12.6055 16 12.1633 16 11.6275V11.5726C16 11.0367 16 10.5945 15.9753 10.2324C15.9497 9.85697 15.8949 9.50969 15.7564 9.17545C15.4316 8.39137 14.8086 7.76841 14.0246 7.44361C13.6903 7.30513 13.343 7.25033 12.9676 7.22473C12.6055 7.20001 12.1633 7.20001 11.6274 7.20001C11.2289 7.20001 11.0296 7.20001 10.8741 7.26945C10.6939 7.34977 10.5498 7.49393 10.4694 7.67409C10.4 7.82961 10.4 8.02889 10.4 8.42745V10.4C10.4 11.7255 9.32552 12.8 8 12.8C6.67448 12.8 5.6 11.7255 5.6 10.4V8.42745Z" fill="#999999"/>
</svg>Import Template</a>`);

                    const element = document.getElementById(`${vm.editorUuid}-import-template`);

                    if (element) {
                        element.addEventListener('click', vm.importTemplateHandler);
                    }
                }

                vm.parseScriptFromContent(vm.modelValue);
            },

            initFontFamilyPlugin () {
                const vm = this;

                Redactor.add('plugin', 'fontfamily', {
                    translations: {
                        en: {
                            "fontfamily": "Font",
                            "remove-font-family":  "Remove Font Family"
                        }
                    },
                    init: function (app) {
                        this.app = app;
                        this.opts = app.opts;
                        this.lang = app.lang;
                        this.inline = app.inline;
                        this.toolbar = app.toolbar;

                        if (vm.emailEditor) {
                            this.fonts = (this.opts.fontfamily) ? this.opts.fontfamily : ["Roboto", "Lato", "Arial", "Verdana", "Georgia", "Times New Roman", "Courier"];
                        }  else {
                            this.fonts = (this.opts.fontfamily) ? this.opts.fontfamily : ["Arial", "Helvetica", "Georgia", "Times New Roman", "Monospace", "Raleway", "Roboto", "Lato", "Open Sans", "Montserrat", "Oswald", "Caveat", "Homemade Apple", "Beth Ellen", "Permanent Marker", "Lora", "Nanum Gothic", "Bebas Neue", "Indie Flower", "Shadows Into Light"];
                        }
                    },
                    start: function () {
                        const dropdown = {};

                        for (let i = 0; i < this.fonts.length; i++) {
                            const font = this.fonts[i];

                            dropdown[i] = {
                                title: font.replace(/'/g, ''),
                                api: 'plugin.fontfamily.set',
                                args: font
                            };
                        }

                        dropdown.remove = {
                            title: this.lang.get('remove-font-family'),
                            api: 'plugin.fontfamily.remove'
                        };

                        const $button = this.toolbar.addButton('fontfamily', { title: this.lang.get('fontfamily') });
                        $button.setIcon('<i class="re-icon-fontfamily"></i>');
                        $button.setDropdown(dropdown);
                    },
                    set: function (value) {
                        const args = {
                            tag: 'span',
                            style: { 'font-family': value},
                            type: 'toggle'
                        };

                        this.inline.format(args);
                    },
                    remove: function () {
                        this.inline.remove({ style: 'font-family' });
                    }
                });
            },

            initDefineLinksCustomPlugin () {
                const vm = this;

                Redactor.add('plugin', 'definedlinks-custom', {
                    translations: {
                        en: {
                            'definedlinks-custom': 'Link',
                        }
                    },
                    init: function (app) {
                        self.app = app;
                        self.lang = app.lang;
                        self.toolbar = app.toolbar;
                    },
                    start: function () {
                        const buttonData = {
                            title: self.lang.get('definedlinks-custom'),
                            api: 'plugin.definedlinks-custom.toggle'
                        };

                        const $button = self.toolbar.addButton('definedlinks-custom', buttonData);
                              $button.setIcon(`<i id="editor-link-${vm.editorUuid}" class="re-icon-link"></i>`);
                    },
                    toggle: function () {
                        vm.redactorEditorRange = vm.redactorEditor.selection.getRange();
                        vm.selectedText = vm.redactorEditor.selection.getText();
                        vm.redactorEditor.selection.save();
                        vm.defineLinkModal = true;
                    },
                });
            },

            initFileManagerCustomPlugin () {
                const vm = this;

                Redactor.add('plugin', 'filemanager-custom', {
                    translations: {
                        en: {
                            'filemanager-custom': 'File',
                        }
                    },
                    init: function (app) {
                        self.app = app;
                        self.lang = app.lang;
                        self.toolbar = app.toolbar;
                    },
                    start: function () {
                        const buttonData = {
                            title: self.lang.get('filemanager-custom'),
                            api: 'plugin.filemanager-custom.toggle'
                        };

                        const $button = self.toolbar.addButton('filemanager-custom', buttonData);
                              $button.setIcon(`<i id="editor-file-${vm.editorUuid}" class="re-icon-file"></i>`);
                    },
                    toggle: function () {
                        vm.redactorEditorRange = vm.redactorEditor.selection.getRange();
                        vm.selectedText = vm.redactorEditor.selection.getText();
                        vm.redactorEditor.selection.save();
                        vm.fileManagerModal = true;
                    },
                });
            },

            initPersonalizePlugin () {
                const vm = this;
                const personalize = vm.defaultPersonalize.concat(vm.personalize);

                Redactor.add('plugin', 'personalize', {
                    translations: {
                        en: {
                            'personalize': 'Personalize',
                        }
                    },
                    init: function (app) {
                        self.app = app;
                        self.lang = app.lang;
                        self.toolbar = app.toolbar;
                    },
                    start: function () {
                        const dropdown = {};

                        personalize.forEach((title) => {
                            const key = title.split(' ').join('_').toLowerCase();

                            dropdown[key] = { title: title, api: 'plugin.personalize.callback' };
                        });

                        const buttonData = { title: self.lang.get('personalize') };

                        const $button = self.toolbar.addButton('personalize', buttonData);
                              $button.setIcon('<i class="fa fa-user"></i>');
                              $button.setDropdown(dropdown);
                    },
                    callback: function (obj, e, c) {
                        vm.redactorEditor.insertion.insertText('{{' +  e + '}}');
                    }
                });
            },

            initEmojiPlugin () {
                const vm = this;

                Redactor.add('plugin', 'emoji', {
                    translations: {
                        en: {
                            'emoji': 'Emoji',
                        }
                    },
                    init: function (app) {
                        self.app = app;
                        self.lang = app.lang;
                        self.toolbar = app.toolbar;
                        self.win = app.$win;
                    },
                    start: function () {
                        const buttonData = {
                            title: self.lang.get('emoji'),
                            api: 'plugin.emoji.toggle'
                        };

                        const $button = self.toolbar.addButton('emoji', buttonData);
                              $button.setIcon(`<i id="editor-emoji-${vm.editorUuid}" class="fa fa-smile"></i>`);
                    },
                    toggle: function () {
                        const btn = self.toolbar.getButton('emoji');
                        btn.hideTooltip();
                        btn.setActive();
                        const element = document.querySelector('.custom-emoji-picker');

                        const isFixed     = self.toolbar.isFixed();
                        const pos         = btn.offset();
                              pos.top     = (isFixed) ? btn.position().top : pos.top;
                        const btnHeight   = btn.height();
                        const btnWidth    = btn.width();
                        const position    = (isFixed) ? 'fixed' : 'absolute';
                        const topOffset   = (isFixed) ? (2 + self.opts.toolbarFixedTopOffset) : 2;
                        const leftOffset  = 0;
                        const left        = (pos.left + leftOffset);
                        const width       = element.offsetWidth;
                        const winWidth    = self.win.width();
                        const leftFix     = element.offsetLeft;//(winWidth < (left + width)) ? (width - btnWidth) : 0;
                        let leftPos       = element.offsetWidth;//(left - leftFix);
                            // leftPos       = (leftPos < 0) ? 4 : leftPos;
                        element.style.position  = position;
                        element.style.top       = '87px';//(pos.top + btnHeight + topOffset) + 'px';
                        element.style.left      = leftPos + 'px';

                        setTimeout(function () {
                            vm.emojiPicker = !vm.emojiPicker;
                        }, 50);

                        vm.emojiButton = btn;

                        if (!vm.emojiPicker) {
                            btn.setInactive();
                        }
                    },
                });
            },

            initEmailButtonsPlugin () {
                const vm = this;
                let buttons = vm.buttons;

                Redactor.add('plugin', 'email-buttons', {
                    translations: {
                        en: {
                            "email-buttons": 'Buttons'
                        }
                    },
                    init: function (app) {
                        self.app = app;
                        self.lang = app.lang;
                        self.toolbar = app.toolbar;
                    },
                    start: function () {
                        const buttonData = {
                            title: self.lang.get('email-buttons'),
                            api: 'plugin.email-buttons.toggle'
                        };

                        const $button = self.toolbar.addButton('email-buttons', buttonData);
                        $button.setIcon(`<i id="email-buttons-${vm.editorUuid}" class="re-icon-properties"></i>`);

                        const $dropdown = this._buildDropdown();
                        $button.setDropdown($dropdown);
                    },
                    _buildDropdown: function () {
                        const dropdown = {};

                        if (buttons.length) {
                            buttons.forEach((button) => {
                                dropdown[button.button_name] = { title: button.button_name, api: 'plugin.email-buttons.callback' };
                            });
                        } else {
                            vm.getEmailButtons().then((resp) => {
                                buttons = resp;

                                buttons.forEach((button) => {
                                    dropdown[button.button_name] = { title: button.button_name, api: 'plugin.email-buttons.callback' };
                                });

                                if (buttons.length == 0) {
                                    dropdown['no_result'] = { title: 'No buttons found' };
                                }
                            });
                        }

                        return dropdown;
                    },
                    callback: function (obj, e) {
                        buttons.forEach((button) => {
                            let padding = "padding: 8px 50px 8px 50px;";
                            let fontSize = "font-size: 16px;"
                            let buttonPosition = "";
                            let url = button.url;
                            let target = (button.target) ? 'target="_blank"': 'target="_self"';
                            let buttonColor = (button.button_style == 'button_outline') ? 'none !important' : button.button_color;
                            let shadow = (button.is_dropshadow) ? 'box-shadow: '+button.shadow_color+' 0px 2px 1px 0px; ' : '' ;
                            let buttonFull = (button.width == 'button_full') ? 'display:block; ' : '' ;
                            let display = buttonPosition ? 'block' : 'flex';

                            if (button.button_size == 'button_medium') {
                                padding = "padding: 15px 80px 15px 80px;";
                                fontSize = "font-size: 20px;";
                            } else if(button.button_size == 'button_large') {
                                padding = "padding: 15px 90px 15px 90px;";
                                fontSize = "font-size: 25px;";
                            }

                            if (button.button_position == 'button_right') {
                                buttonPosition = 'float:right;';
                            } else if(button.button_position == 'button_left') {
                                buttonPosition = 'float:left;';
                            }

                            if (button.button_action == 'download') {
                                url = button.attached_file;
                                target = 'target="_blank"';
                            }

                            if (e == button.button_name) {
                                let buttonContent = '<figure><div style="display:'+ display +'; padding:'+ button.top_padding +'px 0 '+ button.bottom_padding +'px;"><a style="margin: 0 auto; ';
                                    buttonContent += 'background: '+buttonColor+'; text-decoration:none; text-align:center;';
                                    buttonContent += 'color: '+ (button.button_style == 'button_outline' ? button.button_color : button.button_text_color) +'; '+padding+' '+fontSize+' font-weight: bold;';
                                    buttonContent += 'border: 3px solid '+ button.button_color +'; '+ shadow + buttonFull + buttonPosition;
                                    buttonContent += 'border-radius: '+ button.border_radius +'px;" ';
                                    buttonContent += 'href="'+ url +'" '+target+'>' + button.button_text + '</a></div></figure><p></p>';

                                vm.redactorEditor.insertion.insertHtml(buttonContent);
                            }
                        });
                    }
                });
            },

            initImageLibraryPlugin () {
                const vm = this;

                Redactor.add('plugin', 'image-library', {
                    translations: {
                        en: {
                            'image-library': 'Image Library',
                        }
                    },
                    init: function (app) {
                        self.app = app;
                        self.lang = app.lang;
                        self.toolbar = app.toolbar;
                    },
                    start: function () {
                        const buttonData = {
                            title: self.lang.get('image-library'),
                            api: 'plugin.image-library.toggle'
                        };

                        const $button = self.toolbar.addButton('image-library', buttonData);
                              $button.setIcon(`<i id="editor-image-${vm.editorUuid}" class="fa fa-image"></i>`);
                    },
                    callback: function (obj, e, c) {
                        vm.redactorEditor.insertion.insertText('{{' +  e + '}}');
                    },
                    toggle: function () {
                        vm.redactorEditorRange = vm.redactorEditor.selection.getRange();
                        vm.imageLibraryModal = true;
                    },
                });
            },

            setEditorFocus () {
                const vm = this;

                vm.redactorEditor.editor.focus();
            },

            checkEditorFocus () {
                const vm = this;

                if (!vm.redactorEditor.selection.is()) {
                    vm.redactorEditor.editor.endFocus();
                }
            },

            initFontColorPlugin () {
                const vm = this;

                Redactor.add('plugin', 'fontcolor-custom', {
                    translations: {
                        en: {
                            'fontcolor-custom': 'Text Color',
                            "text": "Text",
                            "highlight": "Highlight"
                        },
                    },
                    init: function (app) {
                        this.app        = app;
                        this.lang       = app.lang;
                        this.toolbar    = app.toolbar;
                        this.inline     = app.inline;
                        this.rule       = 'color';
                    },
                    start: function () {
                        const buttonData = {
                            title: this.lang.get('fontcolor-custom'),
                            api: 'plugin.fontcolor-custom.toggle'
                        };

                        const $button = this.toolbar.addButton('fontcolor-custom', buttonData);
                        $button.setIcon(`<i id="fontcolor-pickr-${vm.editorUuid}" class="re-icon-fontcolor"></i>`);

                        const $dropdown = this._buildDropdown();
                        $button.setDropdown($dropdown);
                    },
                    _buildDropdown: function () {
                        var $dropdown = Redactor.dom('<div class="redactor-dropdown-cells">');

                        this.$selector = this._buildSelector();

                        this.$selectorText = this._buildSelectorItem('text', this.lang.get('text'));
                        this.$selectorText.addClass('active');
                        this.$selectorBack = this._buildSelectorItem('back', this.lang.get('highlight'));


                        if (vm.showFontHighlight) {
                            this.$selector.append(this.$selectorText);
                            this.$selector.append(this.$selectorBack);
                        }

                        this.$pickerText = this._buildPicker('textcolor');

                        $dropdown.append(this.$selector);
                        $dropdown.append(this.$pickerText);

                        this._buildSelectorEvents();
                        $dropdown.width(242);

                        return $dropdown;
                    },
                    _buildSelector: function() {
                        var $selector = Redactor.dom('<div>');
                        $selector.addClass('redactor-custom-dropdown redactor-dropdown-selector');

                        return $selector;
                    },
                    _buildSelectorItem: function(name, title) {
                        var $item = Redactor.dom('<span>');
                        $item.attr('rel', name).html(title);
                        $item.addClass('redactor-dropdown-not-close');

                        return $item;
                    },
                    _buildPicker: function(name) {
                        var $box    = Redactor.dom('<div class="re-dropdown-box-' + name + '">');
                        var $picker = Redactor.dom(`<span id="color-picker-${vm.editorUuid}" class="fontcolor-pickr">`);
                        $box.append($picker);

                        return $box;
                    },
                    _buildSelectorEvents: function()
                    {
                        this.$selectorText.on('mousedown', function(e)
                        {
                            e.preventDefault();

                            this.$selector.find('span').removeClass('active');
                            this.$selectorText.addClass('active');
                            this.rule = 'color';
                        }.bind(this));

                        this.$selectorBack.on('mousedown', function(e)
                        {
                            e.preventDefault();

                            this.$selector.find('span').removeClass('active');
                            this.$selectorBack.addClass('active');
                            this.rule = 'background-color';
                        }.bind(this));
                    },
                    toggle: function () {
                        const btn = this.toolbar.getButton('fontcolor-custom');
                        btn.hideTooltip();
                        btn.setActive();

                        vm.fontColorPicker = !vm.fontColorPicker;

                        if (!vm.fontColorPicker) {
                            btn.setInactive();
                        }
                    },
                    ondropdown: {
                        open: function(e, dropdown, button) {
                            if (dropdown.name === 'fontcolor-custom') {
                                vm.redactorEditorRange = vm.redactorEditor.selection.getRange();
                                vm.selectedText = vm.redactorEditor.selection.getText();
                                vm.redactorEditor.selection.save();
                            }

                            const swatches = JSON.parse(JSON.stringify(vm.favoriteColors));

                            if (swatches.indexOf('#ffff00') === -1) {
                                swatches.push('#ffff00');
                            }

                            if (dropdown.name === 'fontcolor-custom' && !vm.isCustomColorPickerInit) {
                                vm.colorPicker = Pickr.create({
                                    el: `#color-picker-${vm.editorUuid}`,
                                    theme: 'monolith',
                                    appClass: 'redactor-color-picker',
                                    swatches,
                                    inline: true,
                                    showAlways: true,
                                    useAsButton: true,
                                    components: {
                                        opacity: true,
                                        hue: true,
                                        interaction: {
                                            input: true,
                                            clear: true,
                                        },
                                    },
                                }).on('change', (color, source, instance) => {
                                    let style = {};
                                    style[this.rule] = color.toHEXA().toString();
                                    const args = { tag: 'span', style: style, type: 'toggle' };

                                    this.inline.format(args);
                                }).on('changestop', (source, instance) => {
                                    if (source == 'input') {
                                        let style = {};
                                        style[this.rule] = instance._color.toHEXA().toString();

                                        vm.colorPickerArgs = { tag: 'span', style: style, type: 'toggle' };
                                        vm.colorPickerObj = this;
                                        vm.colorPickersource = 'input';
                                    } else {
                                        vm.colorPickerArgs = {};
                                        vm.colorPickerObj = {};
                                        vm.colorPickersource = 'other';
                                    }
                                }).on('clear', instance => {
                                    this.inline.remove({ style: this.rule });
                                });

                                vm.isCustomColorPickerInit = true;
                            }
                        },
                        close: function(e, dropdown, button) {
                            if (dropdown && dropdown.name === 'fontcolor-custom' && vm.colorPickersource == 'input') {
                                vm.redactorEditor.selection.restore();
                                vm.colorPickerObj.inline.format(vm.colorPickerArgs);
                                vm.redactorEditor.selection.save();

                                setTimeout(function () {
                                    vm.colorPickerArgs = {};
                                    vm.colorPickerObj = {};
                                    vm.colorPickersource = 'other';
                                }, 100);
                            }
                        }
                    }
                });
            },

            updateRedactorPluginCloserFunc () {
                const vm = this;

                if (Redactor && Redactor.classes && Redactor.classes['toolbar.dropdown']) {
                    Redactor.classes['toolbar.dropdown'].prototype.close = function(e, animate) {
                        const self = this;

                        if (e) {
                            var $el = Redactor.dom(e.target);
                            if (self._isButton(e) || $el.hasClass('redactor-dropdown-not-close') || $el.hasClass('redactor-dropdown-item-disabled') || $el.hasClass('pcr-color-chooser') || $el.hasClass('pcr-picker') || $el.hasClass('pcr-color-opacity') || $el.hasClass('pcr-result') || $el.hasClass('pcr-selection') || $el.hasClass('redactor-color-picker')) {
                                e.preventDefault();
                                return;
                            }
                        }

                        self.app.broadcast('dropdown.close', self, self.$btn);
                        self.toolbar.setDropdown(false);

                        if (self.$btn) {
                            self.$btn.setInactive();
                        }

                        if (animate === false) {
                            self._close();
                        } else {
                            self.animate.start(self, 'fadeOut', self._close.bind(self));
                        }
                    };
                }
            },

            initSubTextPlugin () {
                const vm = this;

                Redactor.add('plugin', 'sub-text', {
                    translations: {
                        en: {
                            'sub-text': 'Sub Text',
                        }
                    },
                    init: function (app) {
                        self.app = app;
                        self.lang = app.lang;
                        self.toolbar = app.toolbar;
                    },
                    start: function () {
                        const buttonData = {
                            title: self.lang.get('sub-text'),
                            api: 'plugin.sub-text.toggle'
                        };

                        const $button = self.toolbar.addButton('sub-text', buttonData);
                    },
                    toggle: function() {
                        vm.redactorEditor.insertion.insertText('{{sub_text}}');
                        vm.redactorEditor.insertion.insertText('');
                    },
                });
            },

            parseScriptFromContent (content) {
                const vm = this;

                setTimeout(() => {
                    const doc = new DOMParser().parseFromString(content, 'text/html');

                    Array.prototype.slice.call(doc.querySelectorAll("script[src]")).forEach((script) => {
                        if (!vm.isMyScriptLoaded(script.src)) {
                            const src = document.createElement('script');
                            src.setAttribute('src', script.src);
                            document.head.appendChild(src);
                        }
                    });
                }, 500);
            },

            isMyScriptLoaded (src) {
                let url = src;

                if (!src) {
                    url = "http://xxx.co.uk/xxx/script.js";
                }

                const scripts = document.head.getElementsByTagName('script');

                for (let i = scripts.length; i--;) {
                    if (scripts[i].src == url) {
                        return true;
                    }
                }

                return false;
            },
        },
    }
</script>

<style scoped>
    .redactor-container {
        position: relative;
    }

    .redactor-container .modal.secondary.active {
        z-index: +99999999999;
    }

    :deep(.redactor-emoji .vue3-discord-emojipicker__gifbutton),
    :deep(.redactor-emoji .vue3-discord-emojipicker__emojibutton) {
        display: none;
    }

    :deep(.redactor-emoji) {
        position: absolute;
        top: 55px;
        right: 0px;
    }

    :deep(.redactor-statusbar) {
        display: block;
        font-family: Consolas, Menlo, Monaco, "Courier New", monospace;
        margin: 0;
        padding: 10px;
        position: relative;
        overflow: hidden;
        list-style: none;
        background: #f9f9f9;
        box-sizing: border-box;
        border: none;
    }

    :deep(.redactor-statusbar li) {
        float: left;
        font-size: 13px;
        line-height: 16px;
        font-family: "Inter", sans-serif;
        color: rgba(0, 0, 0, 0.8);
        padding: 0 10px;
        line-height: 16px;
        border-right: 1px solid rgba(0, 0, 0, 0.1);
    }

    :deep(.redactor-statusbar li:last-child) {
        border-right-color: transparent;
    }
    /* :deep(.redactor-box.redactor-styles-on) {
        padding-top: 25px !important;
        border-bottom: 0px;
    } */

    /* :deep(.redactor-statusbar) {
        background: #fafafb !important;
        position: absolute;
        top: 0;
        width: 100%;
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
    } */

    .redactor-toolbar-external {
        font-family: "Trebuchet MS", "Helvetica Neue", Helvetica, Tahoma, sans-serif;
        position: relative;
        margin: 0 !important;
        list-style: none !important;
        line-height: 1 !important;
        background: #fafafb;
        box-sizing: border-box;
        padding: 18px 16px;
        border: 1px solid rgba(0, 0, 0, 0.075);
        border-top: 0px;
        margin-top: -1px !important;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
    }

    .redactor-focus + .redactor-toolbar-external {
        border-color: #82b7ec !important;
    }

    .has-error .redactor-toolbar-external {
        border-color: #eb1414;
    }

    :deep(.redactor-styles ol li) {
        list-style: decimal !important;
    }

    :deep(.custom-emoji-picker .dropdown-toggle) {
        display: none;
    }
    :deep(.redactor-toolbar-wrapper){
        z-index: 1;
        position: sticky;
        top: 0;
        left: 0;
    }

    :deep(.redactor-component-active[data-redactor-type=image] img) {
        outline: 2px solid rgba(0, 125, 255, 0.5) !important;
    }

    :deep(.redactor-color-picker.pcr-app[data-theme='monolith']) {
        width: auto;
        max-width: none;
    }

    :deep(.redactor-custom-dropdown.redactor-dropdown-selector) {
        margin-left: 12px;
        margin-right: 12px;
    }

    :deep(.redactor-toolbar-wrapper){
        z-index: 5;
    }

    :deep(.redactor-component[data-redactor-type="widget"]:before), :deep(.redactor-component[data-redactor-type="video"]:before) {
        width: auto;
        height: auto;
    }

    :deep(.redactor-statusbar a.import-template) {
        font-size: 13px;
        line-height: 16px;
        font-family: "Inter", sans-serif;
        font-weight: 500;
        color: #0e101a;
        cursor: pointer;
        text-decoration: none;
        display: flex;
        align-items: center;
    }
    :deep(.redactor-statusbar a.import-template svg) {
        margin-right: 7px;
    }

    :deep(.redactor-statusbar a.import-template:hover) {
        color: rgba(0, 0, 0, 0.5);
    }

    :deep(.redactor-statusbar li:nth-child(2)) {
        border-right-color: transparent;
    }

    :deep(.redactor-statusbar li:nth-child(3)) {
        float: right;
    }
</style>
