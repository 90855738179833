<template>
    <div class="modal secondary" v-if="modelValue" :class="modelValue ? `active ${classlist}` : `${classlist}` ">
        <div class="modal_container">
            <h2>File</h2>
            <form class="setting_wpr w-100">
                <div class="group_item mt-3">
                    <label class="input_label">Name (optional)</label>
                    <div class="field_wpr">
                        <input name="text" v-model="form.name" placeholder="ex. Superfit Coaching, LLC" />
                    </div>
                </div>
                <div class="group_item mt-3">
                    <div class="upload_image mt-2">
                        <div :id="`file-upload-dropzone-${uId}`" class="dropzone">
                            <div class="dz-message" data-dz-message>
                                <div>
                                    <img src="@/assets/images/image2.png" class="icon" style="width: 50px;">
                                    <h4>Drop files here or click to upload</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="group_item">
                    <label for="target" class="checkbox justify-content-start">
                        <input name="target" v-model="form.target" :true-value="1" :false-value="0" type="checkbox" id="target" hidden />
                        <span><i class="fas fa-check"></i></span>&nbsp; Open in new tab
                    </label>
                </div>
                <div class="action_wpr m-0 p-0">
                    <button type="button" class="btn cancel_btn mr-2" @click="closeModal()">Cancel</button>
                    <button type="button" class="btn save_btn" @click="handleInsertFile()">Insert</button>
                </div>
            </form>
        </div>
    </div>

</template>

<script>
    import { mapState } from 'vuex'

    import axios from '@/services/axios'
    import Dropzone from 'dropzone'
    import 'dropzone/dist/dropzone.css'

    export default {
        name: 'Define Link',

        props: {
            modelValue: Boolean,
            insertFile: Function,
            classlist: String,
            setEditorFocus: {
                type: Function,
                default: () => {},
            }
        },

        data () {
            return {
                form: {
                    name: '',
                    file: '',
                    target: 1,
                    url: '',
                    original_name: '',
                },
                uId: Date.now(),
            };
        },

        watch: {
            modelValue (val) {
                if (val) {
                    const vm = this;

                    vm.form = { name: '', file: '', target: 1, url: '', original_name: '' };

                    setTimeout(() => {
                        vm.initDropzone();
                    }, 50);
                }
            },
        },

        computed: mapState({
            user: state => state.authModule.user,
        }),

        methods: {
            closeModal () {
                const vm = this;

                vm.setEditorFocus();
                vm.$emit('update:modelValue', false);
            },

            initDropzone () {
                const vm = this;

                const dropzone = new Dropzone(`#file-upload-dropzone-${vm.uId}`, {
                    url: process.env.VUE_APP_API_URL+'/api/v3/file/upload',
                    uploadMultiple: false,
                    autoProcessQueue: false,
                    paramName: 'images',
                    createImageThumbnails: true,
                    maxFiles:1,
                    init: function() {
                        this.hiddenFileInput.removeAttribute('multiple');
                    },
                    addedfiles: function (files) {
                        const file  = files[0];
                        const form  = new FormData;

                        form.append('name', vm.form.name);
                        form.append('file[]', file);

                        file.previewElement.classList.add("dz-processing");

                        vm.uploadFile(form).then((f) => {
                            vm.form.url = f.url;
                            vm.form.original_name = file.name;

                            file.previewElement.classList.add("dz-success");
                            file.previewElement.classList.add("dz-complete");
                        });
                    },
                });
            },

            resetDropzone (dropzone) {
                const vm = this;

                dropzone.destroy();
                vm.uId = Date.now();

                setTimeout(function () {
                    vm.initDropzone();
                }, 100);
            },

            uploadFile (params) {
                const vm = this;

                return new Promise(function(resolve) {
                    axios.post(`${process.env.VUE_APP_API_URL}/api/v3/redactor/${vm.user.id}/file-upload`, params).then((resp) => {
                        resolve(resp.data['file-0']);
                    }).catch((err) => {
                        resolve(false);
                    });
                });
            },

            handleInsertFile () {
                const vm = this;

                vm.insertFile(vm.form);
            },
        }
    }
</script>
<style scoped>
    .checkbox {
        align-items: center;
        justify-content: flex-start;
        font-size: 14px;
        line-height: 18px;
        margin: 25px 0;
    }

    .upload_image .dropzone {
        border: 2px dashed #95BFFF;
        background: #FAFCFD;
        display: block;
        padding: 25px 25px 15px 25px;
    }
</style>
